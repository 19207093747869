/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css";
import "@fontsource/marcellus";
import AOS from "aos";
import "aos/dist/aos.css";

export const onInitialClientRender = () => {
  AOS.init({
    duration: 700,
    easing: "ease-in-out",
  });
};

export const onClientEntry = () => {
  if (typeof window !== "undefined" && !window.gtag) {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-D9WP7FEWF1";
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;

    gtag("js", new Date());
    gtag("config", "G-D9WP7FEWF1");
  }
};
