import "./src/styles/global.css";
import React from "react";
import "@fontsource/marcellus";
import AOS from "aos";
import "aos/dist/aos.css";

export const onInitialClientRender = () => {
  // Initialize AOS for animations
  AOS.init({
    duration: 700,
    easing: "ease-in-out",
  });

  // Add Statcounter tracking script
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = `
    var sc_project=13081185; 
    var sc_invisible=0; 
    var sc_security="eb71c80e"; 
    var scJsHost = "https://";
    document.write("<sc"+"ript type='text/javascript' src='" + scJsHost +
    "statcounter.com/counter/counter.js'></"+"script>");
  `;
  document.body.appendChild(script);

  // Add noscript fallback for Statcounter
  const noscriptDiv = document.createElement("div");
  noscriptDiv.className = "statcounter";
  noscriptDiv.innerHTML = `
    <a title="Web Analytics Made Easy - Statcounter" 
       href="https://statcounter.com/" 
       target="_blank" 
       rel="noopener noreferrer">
      <img 
        class="statcounter" 
        src="https://c.statcounter.com/13081185/0/eb71c80e/0/"
        alt="Web Analytics Made Easy - Statcounter"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </a>
  `;
  document.body.appendChild(noscriptDiv);

  // Add Statcounter link
  const link = document.createElement("a");
  link.href = "https://statcounter.com/p13081185/?guest=1";
  link.innerText = "Number of Visitors";
  document.body.appendChild(link);
};

export const onClientEntry = () => {
  if (typeof window !== "undefined" && !window.gtag) {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-D9WP7FEWF1";
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;

    gtag("js", new Date());
    gtag("config", "G-D9WP7FEWF1");
  }
};
